<template>
  <Toast />
  <OrganizationForm
    @onSubmit="handleSubmit"
    @uploadLogo="uploadLogo"
    v-if="!loading"
  />
  <ProgressSpinner v-else />
</template>

<script>
import OrganizationForm from '../components/OrganizationForm.vue';
import OrganizationService from '../service/OrganizationService';
import api from '../api/Acutame';

export default {
  components: {
    OrganizationForm,
  },
  mounted() {
    this.organizationService = new OrganizationService();
  },
  data() {
    return {
      loading: false,
      logo: null,
      organizationService: null,
    };
  },
  methods: {
    uploadLogo(logo, cleanup) {
      this.logo = logo;
      cleanup();
    },
    async handleSubmit(newOrganizationConfig) {
      this.loading = true;
      try {
        const createdOrganization = await api.createOrganization(
          newOrganizationConfig
        );
        const { id } = createdOrganization;
        if (this.logo) {
          try {
            await this.organizationService.uploadLogo(id, this.logo);
            this.$toast.add({
              severity: 'info',
              summary: 'Logo subido con éxito',
              life: 3000,
            });
          } catch (error) {
            console.error(error);
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ha habido un error subiendo el logo. Inténtelo más tarde',
              life: 3000,
            });
          }
        }
        this.$router.push(`/organization/${id}`);
      } catch (error) {
        if (error?.response?.status === 409) {
          this.$toast.add({
            severity: 'error',
            summary: 'Número de puntos de venta máximo alcanzado',
            detail:
              'Ha alcanzado el número máximo de puntos de venta para su suscripción actual. Para crear más puntos de venta debe actualizar su plan o desactivar algun punto de venta',
            life: 3000,
          });
        } else {
          console.log(error);
          this.$toast.add({
            severity: 'error',
            summary: 'Error creando la punto de venta',
            detail:
              'Ha habido un error creando la punto de venta. Por favor inténtelo más tarde, si el problema persiste contacte al soporte',
            life: 3000,
          });
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
