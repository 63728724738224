<template>
  <div class="card p-fluid">
    <Toast />
    <h4>
      {{
        isNewOrganization
          ? 'Crear Punto de Venta'
          : 'Ajustes del punto de venta'
      }}
    </h4>
    <template v-if="!loading">
      <form v-if="!loading" @submit="save">
        <span
          class="fixed flex"
          style="right: 3em; gap: 0.5em; top: 8em; z-index: 10"
          v-if="!saving && !isNewOrganization && isUnsaved()"
        >
          <Button @click="cancel()" icon="pi pi-times" label="Cancelar" />
          <Button
            icon="pi pi-check"
            :label="isNewOrganization ? 'Crear' : 'Guardar'"
            type="submit"
            :disabled="smsNameError || phoneFormatError || !isProvinceValid"
          />
        </span>
        <div class="formgrid grid">
          <div class="field col-6">
            <label for="name">Nombre*</label>
            <InputText
              id="name"
              type="text"
              v-model="organization.name"
              required
            />
          </div>
          <div class="field col-6">
            <label for="name">Nombre de sms*</label>
            <InputText
              id="name"
              type="text"
              v-model="organization.smsName"
              required
              v-tooltip.bottom="'Nombre que aparecerá cuando se envíe un sms'"
              :class="{
                'p-invalid': !!smsNameError,
              }"
            />
            <small class="p-invalid" v-if="smsNameError"
              >El nombre de sms no puede ser mayor de 11 caracteres</small
            >
          </div>
          <div class="field col-6">
            <label for="name">Email del punto de venta*</label>
            <InputText
              id="name"
              type="email"
              v-model="organization.email"
              required
            />
          </div>
          <div class="field col-6">
            <label for="phone" required>Teléfono*</label>
            <vue-tel-input
              :defaultCountry="organization.dialCode ?? 'es'"
              @validate="phoneValidationChange"
              @country-changed="phoneCountryChange"
              v-model="organization.phone"
              :autoDefaultCountry="false"
              :autoFormat="false"
              :inputOptions="{ styleClasses: 'p-inputtext' }"
            ></vue-tel-input>
            <small class="p-invalid" v-if="phoneFormatError"
              >Formato de teléfono no válido</small
            >
          </div>
          <div class="field col-6">
            <label for="timezone">Zona horaria*</label>
            <TimezoneSelect
              id="timezone"
              @input="onTimezoneChange"
              :value="selectedTimezone"
              required
            />
          </div>
        </div>
        <h5>Dirección</h5>
        <div class="formgrid grid">
          <div class="field col-6">
            <label for="piso">Linea 1*</label>
            <InputText
              id="line1"
              type="text"
              required
              v-model="organization.address.addressLine1"
            />
          </div>
          <div class="field col-6">
            <label for="streetNumber">Linea 2</label>
            <InputText
              id="line2"
              type="string"
              v-model="organization.address.addressLine2"
            />
          </div>
          <div class="field col-6">
            <label for="population">Población*</label>
            <InputText
              id="population"
              type="text"
              required
              v-model="organization.address.population"
            />
          </div>
          <div class="field col-6">
            <label for="province">Provincia*</label>
            <Dropdown
              v-model="organization.address.province"
              :options="spanishProvinces"
              id="province"
              placeholder="Seleccione una provincia"
              v-if="isSpain"
            />
            <InputText
              id="province"
              type="text"
              required
              v-model="organization.address.province"
              v-else
            />
          </div>
          <div class="field col-6">
            <label for="country">Pais*</label>
            <CountrySelect
              id="country"
              :value="selectedCountry"
              @input="onCountryChange"
            />
          </div>
          <div class="field col-6">
            <label for="zip">CP*</label>
            <InputText
              id="zip"
              type="text"
              required
              v-model="organization.address.zip"
            />
          </div>
        </div>
        <h4>Logo</h4>
        <div class="flex align-items-center" style="gap: 1em">
          <FileUpload
            mode="basic"
            name="demo[]"
            :customUpload="true"
            @uploader="uploadLogo"
            accept="image/png,image/jpg,image/jpeg"
            :auto="true"
            ref="fileupload"
            :maxFileSize="15728640"
            invalidFileSizeMessage="{0}: El archivo es demasiado grande, El archivo debe ser menor que {1} bytes"
          />
          <Image
            v-if="logoString !== null || organization.logoLocation !== null"
            alt="Organization logo"
            :src="logoPath"
            imageStyle="max-height: 4em;"
          />
        </div>
        <Button
          icon="pi pi-user"
          type="submit"
          class="w-full mt-2"
          :disabled="smsNameError || phoneFormatError"
          v-if="!saving && isNewOrganization && isUnsaved()"
        >
          Crear punto de venta
        </Button>
      </form>
    </template>
    <div v-else>LOADING</div>
  </div>
</template>

<script>
import OrganizationService from '../service/OrganizationService';
import countryList from '../constants/countryList';
import provinces from '../constants/provinces';
import { cloneDeep } from 'lodash';
import {toBase64} from '../utils/util';
import timezoneList from '../constants/timezoneList';

export default {
  props: ['originalOrganization'],
  created() {
    this.organizationService = new OrganizationService();
  },
  data() {
    return {
      isNewOrganization: !this.originalOrganization,
      loading: false,
      saving: false,
      selectedCountry: this.originalOrganization
        ? countryList.find(
            (country) =>
              country.code === this.originalOrganization.address.country ||
              country.name === this.originalOrganization.address.country
          ) ?? null
        : null,
      selectedTimezone: this.originalOrganization
        ? timezoneList.find(
            (timezone) => this.originalOrganization.timezone === timezone.code
          ) ?? null
        : null,
      organizationService: null,
      organization: this.originalOrganization
        ? cloneDeep(this.originalOrganization)
        : {
            name: null,
            smsName: null,
            phone: null,
            dialCode: null,
            timezone: null,
            logoLocation: null,
            address: {
              addressLine1: null,
              addressLine2: null,
              street: null,
              population: null,
              province: null,
              country: null,
              zip: null,
            },
          },
      phoneFormatError: false,
      smsNameError: false,
      logoString: null,
      spanishProvinces: provinces,
    };
  },
  watch: {
    ['organization.smsName'](newName) {
      this.smsNameError = newName.length > 11;
    },
    selectedCountry(newCountry) {
      if (
        (newCountry?.code === 'ES' || newCountry?.name === 'Spain') &&
        provinces.indexOf(this.organization.address.province) < 0
      )
        this.organization.address.province = '';
    },
    originalOrganization(newOrg) {
      this.organization = cloneDeep(newOrg);
      this.selectedCountry =
        countryList.find(
          (country) =>
            country.code === this.originalOrganization.address.country ||
            country.name === this.originalOrganization.address.country
        ) ?? null;
      this.selectedTimezone =
        timezoneList.find((timezone) => newOrg.timezone === timezone.code) ??
        null;
      this.isNewOrganization = !newOrg;
      this.logoString = null;
    },
  },
  methods: {
    phoneCountryChange({ dialCode }) {
      this.organization.dialCode = +dialCode;
    },
    phoneValidationChange(event) {
      const isValid = event.valid;
      this.phoneFormatError = !isValid;
    },
    onCountryChange(newCountry) {
      this.selectedCountry = newCountry;
      this.organization.address.country = newCountry.code;
    },
    onTimezoneChange(newTimezone) {
      this.selectedTimezone = newTimezone;
      this.organization.timezone = newTimezone.code;
    },
    isUnsaved() {
      if (!this.originalOrganization) return true;
      const unsaved =
        JSON.stringify(this.originalOrganization).localeCompare(
          JSON.stringify(this.organization)
        ) !== 0;
      return unsaved;
    },
    async save(event) {
      event.preventDefault();
      if (this.smsNameError || this.phoneFormatError || !this.isProvinceValid)
        return;
      this.saving = true;
      this.$emit('onSubmit', this.organization);
      this.saving = false;
    },
    cancel() {
      this.organization = cloneDeep(this.originalOrganization);
      this.selectedCountry =
        countryList.find(
          (country) =>
            country.code === this.originalOrganization.address.country
        ) ?? null;
      this.selectedTimezone =
        timezoneList.find(
          (timezone) => this.originalOrganization.timezone === timezone.code
        ) ?? null;
    },
    uploadLogo(event) {
      this.saving = true;
      const logo = event.files[0];
      toBase64(logo).then(base64Logo => this.logoString = base64Logo);
      this.$emit('uploadLogo', logo, () => {
        this.$refs.fileupload.clear();
        this.saving = false;
      });
    },
  },
  computed: {
    isProvinceValid() {
      return !(
        !this.organization.address.province ||
        this.organization.address.province.length === 0
      );
    },
    isSpain() {
      return (
        this.selectedCountry?.code === 'ES' ||
        this.selectedCountry?.name === 'Spain'
      );
    },
    logoPath() {
      if(this.logoString) return this.logoString;
      return `${process.env.VUE_APP_BASE_URL}/${this.organization.logoLocation}`;
    },
  },
};
</script>
