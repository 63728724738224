export default [
  'Albacete',
  'Alicante',
  'Almería',
  'Asturias',
  'Badajoz',
  'Barcelona',
  'Burgos',
  'Cantabria',
  'Castellón',
  'Ciudad Real',
  'Cuenca',
  'Cáceres',
  'Cádiz',
  'Córdoba',
  'Gerona',
  'Granada',
  'Guadalajara',
  'Guipúzcoa',
  'Huelva',
  'Huesca',
  'Islas Baleares',
  'Jaén',
  'La Coruña',
  'La Rioja',
  'Las Palmas',
  'León',
  'Lugo',
  'Lérida',
  'Madrid',
  'Murcia',
  'Málaga',
  'Navarra',
  'Orense',
  'Palencia',
  'Pontevedra',
  'Salamanca',
  'Santa Cruz de Tenerife',
  'Segovia',
  'Sevilla',
  'Soria',
  'Tarragona',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Vizcaya',
  'Zamora',
  'Zaragoza',
  'Álava',
  'Ávila',
];
